import React, { useEffect, useState } from "react";
import { MapPin } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Box from "../Dashboard/Box";
import GoToTopButton from "../GotoTop";
import Image1 from "../../Assets/Images/skills-3371153_1280.jpg";

const NewCourses = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEventClick = (event) => {
    navigate(`/event/${event.id}`, {
      state: {
        eventDetails: {
          title: event.title,
          hosts: event.teachers.map((teacher) => ({ name: teacher })),
          sessions: [
            {
              date: `${event.date.day}${getDaySuffix(event.date.day)} ${
                event.date.month
              } ${event.date.year}`,
              time: "11:00 AM - 12:00 PM",
              timezone: "+00:00",
              location: event.location,
              price: event.price,
              type: event.type,
            },
          ],
          color: event.color,
          description: getEventDescription(event.type),
        },
      },
    });
  };

  const getDaySuffix = (day) => {
    const num = parseInt(day);
    if (num >= 11 && num <= 13) return "th";
    switch (num % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const getEventDescription = (type) => {
    const descriptions = {
      "In-person CPD":
        "Join us for an engaging In-person CPD session with our expert trainers. Network with fellow professionals and gain hands-on experience.",
      "Online CPD":
        "Participate in our professional development session from anywhere in the world. Interactive online learning with expert guidance.",
      "Bespoke CPD":
        "A customized training session tailored to your specific needs and requirements.",
      "Student Events":
        "Special events designed for students to enhance their learning experience and academic performance.",
    };
    return descriptions[type] || "Join us for this educational event.";
  };
  const events = [
    {
      id: 1,
      title: "Economics Course 1",
      teachers: ["Josh Lury"],
      date: { month: "September", day: "15", year: "2024" },
      location: "London Business School",
      price: "Free",
      type: "In-person CPD",
      color: "#FFB3BA",
    },
    {
      id: 2,
      title: "Economics Course 2",
      teachers: ["Nathan Burns", "Josh Lury"],
      date: { month: "October", day: "06", year: "2024" },
      location: "Hosted on Zoom",
      price: "Free",
      type: "Online CPD",
      color: "#BAFFC9",
    },
    {
      id: 3,
      title: "Economics Course 3",
      teachers: ["Carmel Bones"],
      date: { month: "November", day: "04", year: "2024" },
      location: "Your Institution",
      price: "£99.00",
      type: "Bespoke CPD",
      color: "#BAE1FF",
    },
    {
      id: 4,
      title: "Economics Course 4",
      teachers: ["Nathan Burns", "Josh Lury"],
      date: { month: "December", day: "05", year: "2024" },
      location: "Manchester Conference Center",
      price: "Free",
      type: "Student Events",
      color: "#FFE4BA",
    },
    {
      id: 5,
      title: "Economics Course 5",
      teachers: ["Carmel Bones"],
      date: { month: "January", day: "10", year: "2025" },
      location: "Birmingham University",
      price: "£50.00",
      type: "In-person CPD",
      color: "#FFB3BA",
    },
    {
      id: 6,
      title: "Economics Course 6",
      teachers: ["Josh Lury"],
      date: { month: "February", day: "15", year: "2025" },
      location: "Hosted on Zoom",
      price: "Free",
      type: "Online CPD",
      color: "#BAFFC9",
    },
    {
      id: 7,
      title: "Economics Course 7",
      teachers: ["Nathan Burns"],
      date: { month: "March", day: "20", year: "2025" },
      location: "Your Institution",
      price: "£75.00",
      type: "Bespoke CPD",
      color: "#BAE1FF",
    },
    {
      id: 8,
      title: "Economics Course 8",
      teachers: ["Carmel Bones", "Josh Lury"],
      date: { month: "April", day: "25", year: "2025" },
      location: "Leeds Grammar School",
      price: "Free",
      type: "Student Events",
      color: "#FFE4BA",
    },
    {
      id: 9,
      title: "Economics Course 9",
      teachers: ["Nathan Burns"],
      date: { month: "May", day: "30", year: "2025" },
      location: "Edinburgh Conference Center",
      price: "£120.00",
      type: "In-person CPD",
      color: "#FFB3BA",
    },
    {
      id: 10,
      title: "Economics Course 10",
      teachers: ["Josh Lury"],
      date: { month: "June", day: "05", year: "2025" },
      location: "Hosted on Zoom",
      price: "Free",
      type: "Online CPD",
      color: "#BAFFC9",
    },
    {
      id: 11,
      title: "Economics Course 11",
      teachers: ["Carmel Bones"],
      date: { month: "July", day: "15", year: "2025" },
      location: "Your Institution",
      price: "£99.00",
      type: "Bespoke CPD",
      color: "#BAE1FF",
    },
  ];

  const filteredEvents = selectedType
    ? events.filter((event) => event.type === selectedType)
    : events;

  return (
    <div>
      <section className="c-pageBanner c-pageBanner--courses sectionContainer">
        <div className="c-pageBanner__backgroundOverlay"></div>
        <div className="c-pageBanner__container responsivePadding">
          <div className="c-pageBanner__content">
            <h1 className="c-pageBanner__heading">Events</h1>
          </div>
          <img
            src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero1.jpg"
            alt="Courses banner"
            className="c-pageBanner__img"
          />
        </div>
      </section>

      <section className="sectionContainer1 bgPureWhite">
        <div className="c-coursesTb responsivePadding">
          <div className="c-coursesTb__header">
            <h2 style={{ marginTop: 0 }} className="c-coursesTb__headerHeading">
              Learning with us
            </h2>
          </div>
          <div className="c-coursesTb__thumbnails">
            <div className="c-coursesTb__thumbnail">
              <img
                src="https://demo.nexonetics.com/wp-content/uploads/2025/01/skills-3371153_1280.jpg"
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              <div className="c-tag c-tag--bestSeller">Best seller</div>
              <div className="c-coursesTb__thumbnailTitle">
                In-person CPD CPD
                <p className="thumbnail-description">
                  Attend our In-person CPD training sessions and learn from our
                  senior examiners. Take the opportunity to network with fellow
                  teachers. Our courses are primarily held in London, with
                  additional sessions available in various cities in the UK and
                  around the world.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img
                src="https://demo.nexonetics.com/wp-content/uploads/2025/01/online_cdp.webp"
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              <div className="c-tag c-tag--bestSeller">Best seller</div>
              <div className="c-coursesTb__thumbnailTitle">
                Online CPD
                <p className="thumbnail-description">
                  Engage in professional development from any part of the world.
                  We provide precise and extensive training across
                  qualifications that cover a range of challenging content and
                  assessment. Our short focussed sessions keep staff engaged at
                  all times.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img
                src="https://demo.nexonetics.com/wp-content/uploads/2025/01/cpd-continuing-professional-development-symbol-600nw-2045746799.webp"
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              <div className="c-tag c-tag--bestSeller">Best seller</div>

              <div className="c-coursesTb__thumbnailTitle">
                Bespoke CPD
                <p className="thumbnail-description">
                  We offer customised in-house training tailored to your
                  specific needs. You select the focus area. Our senior
                  examiners will design and run a bespoke programme that
                  seamlessly aligns with your department's targets and
                  institution’s priorities.
                </p>
              </div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img
                src="https://demo.nexonetics.com/wp-content/uploads/2025/01/concentrated-girl-taking-notes-classroom-1-1-1-scaled.jpg"
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              <div className="c-tag c-tag--bestSeller">Best Seller</div>
              <div className="c-coursesTb__thumbnailTitle">
                Student events
                <p className="thumbnail-description">
                  The objective of our in-house events is to enhance pupil
                  learning. Conducted by senior examiners, these events provide
                  effective learning strategies and guidance on improving the
                  quality of written work. The result: higher overall pupil
                  grades.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sectionContainer1">
        <div style={{ paddingLeft: 0, paddingRight: 0 }} className="p-6">
          <div className="bg-amber-500/50 p-4 rounded-lg mb-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="text-left w-full p-2 border rounded-md bg-white">
                Economics
              </div>
              <div className="text-left w-full p-2 border rounded-md bg-white">
                Edexcel
              </div>
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="w-full p-2 border rounded-md hover:border-amber-500 focus:ring-2 focus:ring-amber-500 focus:outline-none transition-all"
              >
                <option value="">All Types</option>
                <option value="In-person CPD">In-person CPD</option>
                <option value="Online CPD">Online CPD</option>
                <option value="Bespoke CPD">Bespoke CPD</option>
                <option value="Student Events">Student events</option>
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {filteredEvents.map((event) => (
              <div
                key={event.id}
                onClick={() => handleEventClick(event)}
                className="border rounded-lg shadow-sm p-6 relative cursor-pointer 
                     transition-all duration-300 ease-in-out
                     hover:shadow-lg hover:scale-[1.02] hover:border-amber-500
                     active:scale-[0.98] 
                     bg-white hover:bg-gray-50"
              >
                <div
                  style={{
                    backgroundColor: event.color,
                    color: "black",
                    borderRadius: 8,
                  }}
                  className="absolute top-0 right-0 text-sm px-3 py-1 rounded-tr-lg"
                >
                  {event.type}
                </div>

                <div className="flex flex-row items-start gap-6 sm:gap-28">
                  <div className="text-center min-w-24 group">
                    <div
                      style={{ backgroundColor: "rgb(32 123 191)" }}
                      className="text-white  py-1 rounded-t-md w-24 
                              transition-colors duration-300 group-hover:bg-blue-700"
                    >
                      {event.date.month}
                    </div>
                    <div
                      className="border-x border-b rounded-b-md  py-2 w-24
                              transition-colors duration-300 group-hover:bg-gray-50"
                    >
                      <div className="text-2xl font-bold">{event.date.day}</div>
                      <div className="text-sm text-gray-600">
                        {event.date.year}
                      </div>
                    </div>
                  </div>

                  <div className="space-y-2 text-left flex-grow">
                    <h3
                      className="text-lg font-semibold group-hover:text-amber-600 
                             transition-colors duration-300"
                    >
                      {event.title}
                    </h3>
                    <p className="text-gray-600">{event.teachers.join(", ")}</p>

                    <div className="flex items-center text-gray-600">
                      <MapPin className="w-4 h-4 mr-2" />
                      <span>{event.location}</span>
                    </div>

                    <p className="text-gray-800 font-medium">
                      {event.price}
                      {event.price !== "Free" && (
                        <span className="text-sm text-gray-500"> (ex VAT)</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" bgContainer linearBG">
        <Box />
        <section className="sectionContainer">
          <div className="c-cta">
            <div className="c-cta__title">Get in touch</div>
            <p className="c-cta__intro">
              If you require additional information or have any queries,
              <br /> please feel free to send us a message.
            </p>
            <button
              className="c-button ctaOrange"
              style={{
                background: "rgb(66 66 66)",
                border: "none",
                color: "#fff",
              }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact us
            </button>
          </div>
        </section>
      </div>

      <GoToTopButton />
    </div>
  );
};

export default NewCourses;
