import React, { useState, useEffect } from "react";
import { Star, ChevronLeft, ChevronRight, Quote } from "lucide-react";

const testimonials = [
  {
    id: 1,
    review:
      "EduConnect has transformed the way I teach my classes. The interactive lesson planning tools and real-time student progress tracking have made it easier to identify where students need additional support. The platform's ability to integrate multimedia content has significantly increased student engagement in my virtual classroom.",
    stars: 5,
    authorName: "Dr. Sarah Mitchell",
    designation: "High School Science Teacher",
  },
  {
    id: 2,
    review:
      "As a student, this platform has completely changed how I learn. The personalized learning paths and instant feedback on assignments help me stay on track. I especially love the collaborative features that let me work with classmates on group projects, even when we're studying remotely.",
    stars: 5,
    authorName: "James Chen",
    designation: "12th Grade Student",
  },
  {
    id: 3,
    review:
      "The analytics dashboard has given me unprecedented insights into my students' learning patterns. I can easily identify concepts that need reinforcement and adjust my teaching strategy accordingly. The automated grading system has also saved me countless hours, allowing me to focus more on actual teaching.",
    stars: 5,
    authorName: "Maria Rodriguez",
    designation: "Middle School Math Teacher",
  },
  {
    id: 4,
    review:
      "What I appreciate most about this platform is how it adapts to different learning styles. The variety of content formats - from video lectures to interactive quizzes - helps me grasp complex concepts more easily. The mobile app means I can continue learning even when I'm on the go.",
    stars: 5,
    authorName: "Alex Thompson",
    designation: "University Student",
  },
];

const TestimonialCard = ({ testimonial, isVisible }) => (
  <div
    className={`transform transition-all duration-500 ease-in-out ${
      isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
    } flex flex-col items-center p-6 mx-auto max-w-3xl`}
  >
    <div className="relative mb-8">
      <img
        src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero1.jpg"
        alt={testimonial.authorName}
        className="w-24 h-24 rounded-full object-cover border-4 border-blue-500 transition-transform duration-300 hover:scale-105"
      />
      <div
        style={{ backgroundColor: "rgb(32 123 191)" }}
        className="absolute -right-2 -bottom-2 bg-blue-500 rounded-full p-2"
      >
        <Quote className="w-4 h-4 text-white" />
      </div>
    </div>

    <div className="flex gap-1 mb-4">
      {[...Array(testimonial.stars)].map((_, i) => (
        <Star
          key={i}
          className="w-5 h-5 text-yellow-400 fill-current transition-transform duration-300 hover:scale-110"
        />
      ))}
    </div>

    <p className="text-lg text-center text-gray-700 mb-6 italic">
      "{testimonial.review}"
    </p>

    <h3 className="text-xl font-bold text-gray-900 mb-1">
      {testimonial.authorName}
    </h3>
    <p className="text-sm text-gray-500">{testimonial.designation}</p>
  </div>
);

const ModernTestimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let interval;
    if (isAutoPlay) {
      interval = setInterval(() => {
        handleNext();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlay, currentIndex]);

  const handlePrevious = () => {
    setIsAutoPlay(false);
    setIsVisible(false);
    setTimeout(() => {
      setCurrentIndex(
        (prev) => (prev - 1 + testimonials.length) % testimonials.length
      );
      setIsVisible(true);
    }, 300);
  };

  const handleNext = () => {
    setIsAutoPlay(false);
    setIsVisible(false);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
      setIsVisible(true);
    }, 300);
  };

  const handleDotClick = (index) => {
    setIsAutoPlay(false);
    setIsVisible(false);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsVisible(true);
    }, 300);
  };

  return (
    <div className="relative bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        {/* Main Testimonial Display */}
        <div className="relative overflow-hidden min-h-[400px]">
          <TestimonialCard
            testimonial={testimonials[currentIndex]}
            isVisible={isVisible}
          />
        </div>

        {/* Navigation Controls */}
        <div className="flex justify-center items-center gap-4 mt-8">
          <button
            style={{ backgroundColor: "rgb(32 123 191)" }}
            onClick={handlePrevious}
            className="p-2 rounded-full bg-blue-500 text-white transition-all duration-300 hover:bg-blue-600 hover:shadow-lg transform hover:-translate-x-1"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>

          {/* Dots */}
          <div className="flex gap-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => handleDotClick(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 transform hover:scale-110 ${
                  index === currentIndex
                    ? "bg-blue-500 scale-110"
                    : "bg-gray-300 hover:bg-blue-300"
                }`}
              />
            ))}
          </div>

          <button
            onClick={handleNext}
            style={{ backgroundColor: "rgb(32 123 191)" }}
            className="p-2 rounded-full bg-blue-500 text-white transition-all duration-300 hover:bg-blue-600 hover:shadow-lg transform hover:translate-x-1"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModernTestimonial;
