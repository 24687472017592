import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../cart/CartProvider";
import GoToTopButton from "../GotoTop";
import Box from "../Dashboard/Box";
import ModernTestimonial from "../Course/ModernTestimonial";

const economicsCourses = [
  {
    title: "GCE Economics A",
    exam: "Edexcel",
    themes: [
      {
        name: "Theme 1 - Introduction to markets and market failure",
        price: "£79.99",
      },
      {
        name: "Theme 2 - The UK economy - performance and policies",
        price: "£79.99",
      },
      {
        name: "Theme 3 - Business behaviour and the labour market",
        price: "£79.99",
      },
      { name: "Theme 4 - A global perspective", price: "£79.99" },
      { name: "All four Themes - Complete diagram package", price: "£249.99" },
    ],
  },
  {
    title: "GCE Economics B",
    exam: "Edexcel",
    themes: [
      { name: "Theme 1 - Markets, consumers and firms", price: "£49.99" },
      { name: "Theme 2 - The wider economic environment", price: "£49.99" },
      { name: "Theme 3 - The global economy", price: "£49.99" },
      { name: "Theme 4 - Making markets work", price: "£49.99" },
      { name: "All four Themes - Complete diagram package", price: "£149.99" },
    ],
  },
  {
    title: "IAL Economics",
    exam: "Edexcel",
    themes: [
      { name: "Unit 1 - Markets in action", price: "£79.99" },
      {
        name: "Unit 2 - Macroeconomic performance and policy",
        price: "£79.99",
      },
      { name: "Unit 3 - Business behaviour", price: "£79.99" },
      { name: "Unit 4 - Developments in the global economy", price: "£79.99" },
      { name: "All four Units - Complete diagram package", price: "£249.99" },
    ],
  },
  {
    title: "GCE Economics",
    exam: "AQA",
    themes: [
      {
        name: "Micro - Individuals, firms, markets and market failure",
        price: "£149.99",
      },
      {
        name: "Macro - The national and international economy",
        price: "£149.99",
      },
      { name: "Both units - Complete diagram package", price: "£249.99" },
    ],
  },
  {
    title: "GCE Economics",
    exam: "OCR",
    themes: [
      { name: "Component 1 - Microeconomics", price: "£149.99" },
      { name: "Component 2 - Macroeconomics", price: "£149.99" },
      { name: "Both components - Complete diagram package", price: "£249.99" },
    ],
  },
];

const Resources = () => {
  const navigate = useNavigate();
  const { addToCart } = useCart();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRegister = () => {
    navigate("/sign-up");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleAddToCart = (course, theme) => {
    const resourceItem = {
      title: `${course.title} - ${theme.name}`,
      price: theme.price,
      exam: course.exam,
      type: "resource",
    };
    addToCart(resourceItem);
    navigate("/cart");
  };

  return (
    <div className="font-[Montserrat] text-[18px] leading-[26px] font-normal">
      <section className="c-pageBanner c-pageBanner--courses sectionContainer">
        <div className="c-pageBanner__backgroundOverlay"></div>
        <div className="c-pageBanner__container responsivePadding">
          <div className="c-pageBanner__content">
            <h1 className="c-pageBanner__heading">Resources</h1>
          </div>
          <img
            src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero1.jpg"
            alt="Resources banner"
            className="c-pageBanner__img"
          />
        </div>
      </section>

      <section className="sectionContainer1">
        <div className="c-text c-text--aboutUs responsivePadding">
          <div>
            <p className="c-pageBanner__intro">
              Learnogic was designed to empower every student to master the art
              of writing and to take it to the next level.
              <br /> <br /> Our focus is on developing a bank of board-specific
              questions, by topic, for a range of essay-based subjects that
              require careful integration of all the assessment objectives when
              structuring answers. The content has been contributed and verified
              by a team of senior examiners.
            </p>
          </div>
        </div>
      </section>

      <section className="sectionContainer1">
        <div className="space-y-12 text-left">
          {/* <div className="flex gap-4">
          <button
            onClick={handleRegister}
            className="px-6 py-2 border border-yellow-500 text-yellow-500 rounded-md hover:bg-yellow-50 transition-colors"
          >
            Register
          </button>
          <button
            onClick={handleLogin}
            className="px-6 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors"
          >
            Login
          </button>
        </div> */}
          {economicsCourses.map((course, idx) => (
            <div key={idx} className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold mb-2">{course.title}</h2>
              <p className="text-gray-600 mb-6 list-disc">({course.exam})</p>

              <div className="bg-gray-50 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-4">Diagrams</h3>
                <div className="space-y-4">
                  {course.themes.map((theme, themeIdx) => (
                    <div
                      key={themeIdx}
                      className="flex justify-between items-center"
                    >
                      <span className="text-gray-700">{theme.name}</span>
                      <div className="flex items-center space-x-4">
                        <span className="font-medium">{theme.price}</span>
                        <button
                          onClick={() => handleAddToCart(course, theme)}
                          className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-700 transition-colors"
                        >
                          Buy
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <ModernTestimonial />
      <div className="bgContainer linearBG">
        <Box />
      </div>

      <GoToTopButton />
    </div>
  );
};

export default Resources;
