import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import BrowserRouter, Routes, and Route
import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import Footer from "./Components/Footer";
import NewNavbar from "./Components/Navbar/NewNavbar";
import About from "./Components/About";
import ContactForm from "./Components/contact";
import Course from "./Components/Course";
import Tutors from "./Components/Tutors";
import Teachers from "./Components/Teachers";
import AuthPage from "./Components/view/login";
import NewCourses from "./Components/Course/NewCourses";
import Resources from "./Components/Resources/Resources";
import EventDetailsPage from "./Components/Course/EvenrDetailsPage";
import ExamBoardSelector from "./Components/view/ExamBoardSelector";
import SignupForm from "./Components/view/SignupForm";
import Dashboard1 from "./Components/Course/Dashboard";
import Payment from "./Components/Course/Payment";
import { CartPage, CartProvider } from "./Components/cart/CartProvider.jsx";
import {
  CheckoutPage,
  OrderSuccessPage,
} from "./Components/cart/CheckoutPage.jsx";

function App() {
  return (
    <Router>
      <CartProvider>
        <div className="App">
          <NewNavbar />
          <Routes>
            <Route path="/login" element={<AuthPage />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/order-success" element={<OrderSuccessPage />} />
            <Route path="/dashboard" element={<Dashboard1 />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/subjects" element={<Course />} />
            <Route path="/tutors" element={<Tutors />} />
            <Route path="/teachers-and-examiners" element={<Teachers />} />
            <Route path="/events" element={<NewCourses />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/event/:id" element={<EventDetailsPage />} />
            <Route
              path="/exam-board-registration"
              element={<ExamBoardSelector />}
            />{" "}
            <Route path="/sign-up" element={<SignupForm />} />
          </Routes>
          <Footer />
        </div>
      </CartProvider>
    </Router>
  );
}

export default App;
