import React, { useEffect } from "react";
import "./style.css"; // Make sure to create this CSS file
import Box from "./Dashboard/Box";
import aboutus from "../../src/Assets/Images/aboutUs.jpg";
import GoToTopButton from "./GotoTop";

const Teachers = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner
  }, []);
  return (
    <div>
      <div class="headerSpacing"></div>
      <section class="c-pageBanner c-pageBanner--aboutUs sectionContainer">
        <div class="c-pageBanner__backgroundOverlay"></div>
        <div class="c-pageBanner__container responsivePadding">
          <div style={{ textAlign: "left" }} class="c-pageBanner__content">
            <h1 class="c-pageBanner__heading">
              Careers
              {/* <br />
              <p
                style={{
                  width: 225,
                  textAlign: "end",
                  fontWeight: 400,
                  color: "black",
                  fontSize: 25,
                }}
              >
                Work with us
              </p> */}
            </h1>
          </div>
          <img src={aboutus} alt="" class="c-pageBanner__img" />
        </div>
      </section>{" "}
      <section className="sectionContainer1">
        <div className="c-text c-text--aboutUs responsivePadding">
          <div className="c-coursesTb__header">
            <h2
              style={{ marginTop: 0, paddingTop: 0 }}
              className="c-coursesTb__headerHeading"
            >
              Work with us
            </h2>
          </div>
          <div>
            <p className="c-pageBanner__intro">
              At Learnogic, we value working with creative, dedicated and
              motivated people who look to share good teaching practices and
              develop student learning. We appreciate the significance of being
              a senior examiner – be it an assistant principal or a team leader
              – and the contribution your many years of teaching and examining
              experience can make in enhancing the writing skills of students.
              Whether you are aiming to diversify your work portfolio, leaving
              education, or are looking for a fresh challenge, we could be your
              calling. If you wish to be a key part of our innovative journey in
              enriching every students learning experience or would simply like
              to find out more about the roles within our subjects, get in
              touch.
            </p>
          </div>
        </div>
      </section>
      <div className="button-container">
        <button
          onClick={() => (window.location.href = "/contact")}
          className="c-button"
          style={{ display: "block" }}
        >
          Contact us
        </button>
      </div>
      <div className="bgContainer linearBG">
        <Box />
      </div>
      <GoToTopButton />
    </div>
  );
};

export default Teachers;
