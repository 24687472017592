import React, { useEffect, useState } from "react";
import { Share2, Heart } from "lucide-react";
import { useCart } from "../cart/CartProvider";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Box from "../Dashboard/Box";
import ModernTestimonial from "./ModernTestimonial";
import GoToTopButton from "../GotoTop";

const EventDetailsPage = () => {
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("why-attend");

  // Get event details from navigation state
  const event = location.state?.eventDetails || {
    title: "Event not found",
    hosts: [{ name: "Unknown Host" }],
    sessions: [
      {
        date: "N/A",
        time: "N/A",
        timezone: "N/A",
        location: "N/A",
        price: "N/A",
        type: "N/A",
      },
    ],
    description: "Event details not available",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddToCart = () => {
    const eventItem = {
      title: event.title,
      price: event.sessions[0].price,
      date: event.sessions[0].date,
      time: event.sessions[0].time,
      location: event.sessions[0].location,
      type: "event",
    };
    addToCart(eventItem);
    navigate("/cart");
  };

  const programmeSchedule = [
    { time: "11:00 AM", title: "Welcome and Introduction" },
    {
      time: "11:15 AM",
      title: "Main Session",
      details: [
        "Key concepts and principles",
        "Interactive discussion and examples",
        "Practical applications",
      ],
    },
    { time: "12:00 PM", title: "Q&A and Closing Remarks" },
  ];

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 py-28">
        <div className="grid grid-cols-6 gap-8">
          <div className="col-span-2 bg-gray-100 rounded-lg p-8 flex items-center justify-center h-[500px]">
            <img
              src="https://media.hachettelearning.com/medialibraries/hodder/website/images/contact-vector.svg?width=339"
              alt="Event illustration"
              className="w-full h-full object-contain"
            />
          </div>

          <div className="col-span-4">
            <div className="flex justify-between items-center mb-6">
              <button className="flex items-center gap-2 bg-white rounded-full px-4 py-2 shadow">
                <Share2 className="w-4 h-4" />
                <span>Share Event</span>
              </button>
              <button className="text-gray-600">
                <Heart className="w-6 h-6" />
              </button>
            </div>

            <div className="space-y-4 mb-8">
              <div className="flex gap-3">
                <span
                  style={{ backgroundColor: event.color || "rgb(32 123 191)" }}
                  className="text-black px-6 py-1.5 rounded text-sm"
                >
                  {event.sessions[0].type}
                </span>
              </div>
              <h1 className="text-left text-3xl font-bold">{event.title}</h1>
            </div>

            <div className="flex flex-wrap gap-8 mb-8 justify-between">
              {event.hosts.map((host, index) => (
                <div key={index} className="flex items-center gap-4">
                  <div className="w-16 h-16 bg-gray-600 rounded-full flex items-center justify-center text-white text-xl">
                    {host.initials ||
                      host.name
                        .split(" ")
                        .map((n) => n[0])
                        .join("")}
                  </div>
                  <div>
                    <p className="text-gray-600 text-sm text-left">Hosted By</p>
                    <p className="font-medium text-lg text-left">{host.name}</p>
                  </div>
                </div>
              ))}
              <div className="mt-4 flex justify-end">
                <button
                  onClick={handleAddToCart}
                  style={{ backgroundColor: "rgb(32 123 191)" }}
                  className="bg-blue-900 text-white px-8 py-3 rounded hover:bg-blue-800 transition-colors"
                >
                  Add to cart
                </button>
              </div>
            </div>

            <div className="mt-8 space-y-4">
              {event.sessions.map((session, index) => (
                <div
                  key={index}
                  className="bg-gray-50 rounded-lg p-6 text-left"
                >
                  <div>
                    <h6 className="font-medium mb-2">Location:</h6>
                    <p>{session.location}</p>
                  </div>

                  <div className="mt-4">
                    <h6 className="font-medium mb-2">Date & Time:</h6>
                    <div className="flex items-start gap-2">
                      <div
                        style={{ backgroundColor: "rgb(32 123 191)" }}
                        className="w-3 h-3 rounded-full border-2 border-blue-600 mt-1.5"
                      />
                      <div>
                        <p>{`${session.date}, ${session.time}, ${session.timezone}`}</p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 text-right">
                    <p className="text-4xl font-bold">{session.price}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="spacing-48px"></div>
        <div className="spacing-48px"></div>

        <div className="max-w-7xl mx-auto p-6">
          <nav className="border-b border-gray-200 mb-6">
            <ul className="flex space-x-8">
              {[
                { id: "why-attend", label: "Why attend?" },
                { id: "programme", label: "Programme" },
                { id: "trainers", label: "Trainer(s)" },
              ].map((tab) => (
                <li
                  key={tab.id}
                  className={` pl-0 cursor-pointer pb-2 border-b-2 transition-all duration-200 ${
                    activeTab === tab.id
                      ? "text-amber-500 font-semibold border-amber-500"
                      : "text-gray-600 border-transparent hover:text-amber-400 hover:border-amber-400"
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </nav>

          <div className="mt-6">
            {activeTab === "why-attend" && (
              <div className="space-y-6 bg-gray-50 p-6 rounded-lg text-left">
                <h2 className="text-xl font-semibold text-gray-900">
                  {event.title} - Course Overview
                </h2>

                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    {event.description}
                  </p>

                  <div className="space-y-4">
                    <p className="font-medium text-gray-800">Key Benefits:</p>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <span className="text-amber-500 mr-2">→</span>
                        <span>
                          Learn from industry experts and experienced
                          practitioners
                        </span>
                      </li>
                      <li className="flex items-start">
                        <span className="text-amber-500 mr-2">→</span>
                        <span>
                          Gain practical insights and applicable knowledge
                        </span>
                      </li>
                      <li className="flex items-start">
                        <span className="text-amber-500 mr-2">→</span>
                        <span>
                          Network with fellow professionals in your field
                        </span>
                      </li>
                      <li className="flex items-start">
                        <span className="text-amber-500 mr-2">→</span>
                        <span>
                          Receive comprehensive course materials and resources
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-6 space-y-4">
                  <p className="text-sm text-gray-600">
                    Course Reference: {id}
                  </p>
                  <p className="text-sm text-gray-600 italic">
                    Please note: All participants will receive a certificate of
                    completion.
                  </p>
                </div>
              </div>
            )}

            {activeTab === "programme" && (
              <div className="space-y-4 text-left">
                {programmeSchedule.map((item, index) => (
                  <div
                    key={index}
                    className={`p-4 rounded-lg transition-colors duration-200 ${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    }`}
                  >
                    <div className="flex space-x-6">
                      <span className="text-amber-600 font-medium w-20">
                        {item.time}
                      </span>
                      <div className="flex-1">
                        <p className="text-gray-800 font-medium">
                          {item.title}
                        </p>
                        {item.details && (
                          <ul className="mt-2 space-y-2">
                            {item.details.map((detail, idx) => (
                              <li key={idx} className="flex space-x-3">
                                <span className="text-amber-400">→</span>
                                <span className="text-gray-700">{detail}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {activeTab === "trainers" && (
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="space-y-6">
                  {event.hosts.map((host, index) => (
                    <div key={index} className="space-y-4">
                      <h2 className="text-lg font-medium text-gray-900">
                        {host.name}
                      </h2>
                      <div className="flex items-center gap-4">
                        <div className="w-20 h-20 bg-gray-600 rounded-full flex items-center justify-center text-white text-2xl">
                          {host.initials ||
                            host.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("")}
                        </div>
                        <p className="text-gray-700 leading-relaxed flex-1">
                          Expert trainer with extensive experience in{" "}
                          {event.title.toLowerCase()}. Specializes in delivering
                          engaging and informative sessions that combine
                          theoretical knowledge with practical applications.
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModernTestimonial />

      <div className="bgContainer linearBG">
        <Box />
      </div>

      <GoToTopButton />
    </div>
  );
};

export default EventDetailsPage;
