import React, { useState } from "react";
import logo from "../../Assets/Images/Logo-Bird-2.png";
import logo1 from "../../Assets/Images/topicons.png";
import logo2 from "../../Assets/Images/topicons (1).png";

import "./style.css";
import { Link, Route, useNavigate } from "react-router-dom";
import { Search } from "lucide-react";

const NewNavbar = () => {
  const [hang, setHang] = useState(false);
  const history = useNavigate();
  const hangBurger = () => {
    setHang(!hang);
  };
  return (
    <div>
      <header class="c-nav sectionContainer bgBlueDark">
        <div
          style={{
            display: "flex",
            textAlign: "justify",
            justifyContent: "space-between",
            padding: 13,
            // background: "#ffd787d1",
            borderRadius: 12,
          }}
        >
          {/* <a href="">
            <img src={logo} alt="" class="c-nav__logo" />
          </a> */}
          <div>
            <a href="">
              <img src={logo} alt="" class="c-nav__logo" />
            </a>
          </div>
          <div
            onClick={() => {
              window.location.href = "https://demo.nexonetics.com/";
            }}
            style={{ marginLeft: 16, cursor: "pointer" }}
          >
            <div
              style={{
                fontFamily: "Krona One",
                fontSize: 30,
                fontWeight: 700,
                letterSpacing: 2,
                color: "white",
              }}
            >
              Learnogic
            </div>
            <div
              style={{
                fontFamily: "montserrat",
                fontSize: 13,
                fontWeight: 500,
                letterSpacing: 2,
                color: "#fff  ",
                textAlign: "center",
              }}
            >
              excel with flying colours
            </div>
          </div>
        </div>{" "}
        <div class="c-nav__menu">
          <div class="c-nav__links">
            <Link to="/events" class="c-nav__link">
              Events
            </Link>
            <Link to="/resources" class="c-nav__link">
              Resources
            </Link>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                history("/subjects");
              }}
              class="c-nav__link"
            >
              Students
            </div>
            <Link to="/tutors" class="c-nav__link">
              Tutors
            </Link>
            <Link to="/about" class="c-nav__link">
              About
            </Link>
            <Link to="/contact" class="c-nav__link">
              Contact
            </Link>
          </div>
          <div class="c-nav__buttons" style={{ alignItems: "center" }}>
            <a
              type="button"
              style={{ maxWidth: "80%" }}
              href="https://demo.nexonetics.com/upgrade/"
            >
              {/* <img src={logo1} style={{ width: 34 }} /> */}
              <svg
                width="30"
                height="30"
                viewBox="0 0 227 226"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M68.5473 37.7876V52.8114H211.273V82.5514L194.606 135.442H63.8205L45.0408 0.209961H0.940445V15.2337H31.9589L50.7385 150.466H205.624L226.297 84.8622V37.7876H68.5473Z"
                  fill="white"
                  stroke="white"
                  stroke-width="8"
                />
                <path
                  d="M76.5212 165.633C68.5549 165.642 60.9174 168.811 55.2844 174.444C49.6514 180.077 46.4827 187.714 46.4737 195.68C46.4737 203.649 49.6394 211.292 55.2744 216.927C60.9094 222.562 68.5521 225.728 76.5212 225.728C84.4903 225.728 92.133 222.562 97.7679 216.927C103.403 211.292 106.569 203.649 106.569 195.68C106.56 187.714 103.391 180.076 97.7581 174.443C92.125 168.81 84.4875 165.642 76.5212 165.633ZM76.5212 210.704C73.5497 210.704 70.6451 209.823 68.1744 208.172C65.7038 206.521 63.7781 204.175 62.641 201.43C61.5039 198.684 61.2064 195.664 61.7861 192.749C62.3658 189.835 63.7967 187.158 65.8978 185.057C67.9989 182.956 70.6758 181.525 73.5902 180.945C76.5045 180.366 79.5253 180.663 82.2705 181.8C85.0157 182.937 87.3621 184.863 89.013 187.334C90.6638 189.804 91.5449 192.709 91.5449 195.68C91.5402 199.663 89.9558 203.482 87.1393 206.298C84.3229 209.115 80.5043 210.699 76.5212 210.704Z"
                  fill="white"
                  stroke="white"
                  stroke-width="8"
                />
                <path
                  d="M181.687 165.633C173.721 165.642 166.084 168.811 160.451 174.444C154.818 180.077 151.649 187.714 151.64 195.68C151.64 203.649 154.806 211.292 160.441 216.927C166.076 222.562 173.718 225.728 181.687 225.728C189.657 225.728 197.299 222.562 202.934 216.927C208.569 211.292 211.735 203.649 211.735 195.68C211.726 187.714 208.557 180.076 202.924 174.443C197.291 168.81 189.654 165.642 181.687 165.633ZM181.687 210.704C178.716 210.704 175.811 209.823 173.341 208.172C170.87 206.521 168.944 204.175 167.807 201.43C166.67 198.684 166.373 195.664 166.952 192.749C167.532 189.835 168.963 187.158 171.064 185.057C173.165 182.956 175.842 181.525 178.756 180.945C181.671 180.366 184.692 180.663 187.437 181.8C190.182 182.937 192.528 184.863 194.179 187.334C195.83 189.804 196.711 192.709 196.711 195.68C196.706 199.663 195.122 203.482 192.306 206.298C189.489 209.115 185.671 210.699 181.687 210.704Z"
                  fill="white"
                  stroke="white"
                  stroke-width="8"
                />
              </svg>
            </a>

            <a
              type="button"
              style={{ maxWidth: "80%" }}
              href="https://demo.nexonetics.com/my-account/"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 254 272"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="123.5"
                  cy="60.5"
                  r="54.5"
                  stroke="white"
                  strokeWidth="25"
                />
                <path
                  d="M247.5 226.5C247.5 240.19 244.204 248.383 239.299 253.564C234.305 258.84 226.677 262.026 215.852 263.627C205.02 265.228 191.882 265.121 176.675 264.557C172.807 264.413 168.808 264.24 164.701 264.063C152.87 263.551 140.135 263 127 263C113.865 263 101.13 263.551 89.2992 264.063C85.1917 264.24 81.1932 264.413 77.3248 264.557C62.1183 265.121 48.9803 265.228 38.1481 263.627C27.3228 262.026 19.6951 258.84 14.7006 253.564C9.79556 248.383 6.5 240.19 6.5 226.5C6.5 172.296 59.3308 126.5 127 126.5C194.669 126.5 247.5 172.296 247.5 226.5Z"
                  stroke="white"
                  strokeWidth="25"
                />
              </svg>
            </a>
          </div>
        </div>
        <div class="c-nav__hamburger" onClick={hangBurger}>
          <img
            src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/hamburgerClosed.svg"
            alt=""
            class="c-nav__hamburgerImg"
          />
        </div>
        {hang && (
          <div class="c-nav__menu--mobile bgBlueDark">
            <div class="c-nav__links--mobile">
              <Link to="/" class="c-nav__link--mobile">
                Home
              </Link>
              <Link to="/subjects" class="c-nav__link--mobile">
                Subjects
              </Link>
              <Link to="/tutors" class="c-nav__link--mobile">
                Subjects
              </Link>
              <Link to="/about" class="c-nav__link--mobile">
                About
              </Link>
              <Link to="/contact" class="c-nav__link--mobile">
                Contact
              </Link>
            </div>
            <div class="c-nav__buttons--mobile">
              <a
                type="button"
                class="c-button1 c-button--whiteOutline c-button--span"
                href="https://demo.nexonetics.com/my-account/"
              >
                Login
              </a>
              <a
                type="button"
                class="c-button1 ctaOrange c-button--span"
                style={{
                  background: "rgb(66 66 66)",
                  border: "none",
                  textDecoration: "none",
                }}
                href="https://demo.nexonetics.com/"
              >
                Get started today
              </a>
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default NewNavbar;
