// CartSystem.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ShoppingCart, Trash2, Plus, Minus, ArrowRight } from "lucide-react";

// Create Cart Context
const CartContext = createContext();

// Cart Provider Component
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);

  // Initialize cart from localStorage
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart") || "[]");
    setCartItems(savedCart);
    setCartCount(savedCart.length);
  }, []);

  // Update localStorage when cart changes
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
    setCartCount(cartItems.length);
  }, [cartItems]);

  const addToCart = (item) => {
    const cartItem = {
      ...item,
      id: Date.now().toString(),
      quantity: 1,
      addedAt: new Date().toISOString(),
    };

    setCartItems((prevItems) => [...prevItems, cartItem]);
  };

  const removeFromCart = (itemId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem("cart");
  };

  const updateQuantity = (itemId, newQuantity) => {
    if (newQuantity < 1) return;

    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      // Handle free items
      if (item.price === "Free" || item.price === "free") {
        return total;
      }

      // Extract numeric value from price string and handle currency symbols
      const price = parseFloat(item.price.replace(/[^0-9.-]+/g, "")) || 0;
      return total + price * item.quantity;
    }, 0);
  };

  const value = {
    cartItems,
    cartCount,
    addToCart,
    removeFromCart,
    clearCart,
    updateQuantity,
    calculateTotal,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

// Custom Hook for Cart
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

// Cart Button Component
export const CartButton = () => {
  const { cartCount } = useCart();
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate("/cart")}
      className="relative p-2 hover:bg-gray-100 rounded-full"
    >
      <ShoppingCart className="w-6 h-6" />
      {cartCount > 0 && (
        <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
          {cartCount}
        </span>
      )}
    </button>
  );
};

// Cart Page Component
export const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity, calculateTotal } =
    useCart();
  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate("/checkout");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (cartItems.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center space-y-8">
            <h1 className="text-4xl font-bold text-gray-900">
              Your Cart is Empty
            </h1>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Looks like you haven't added anything to your cart yet. Browse our
              events and resources to get started!
            </p>
            <div className="flex justify-center gap-6 pt-4">
              <button
                onClick={() => navigate("/events")}
                className="px-8 py-3 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition flex items-center gap-2"
              >
                Browse Events
              </button>
              <button
                onClick={() => navigate("/resources")}
                className="px-8 py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition flex items-center gap-2"
              >
                Browse Resources
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Calculate if cart has any paid items
  const hasOnlyFreeItems = cartItems.every(
    (item) => item.price === "Free" || item.price === "free"
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Section */}
      <div className="bg-white border-b pt-32">
        <div className="max-w-7xl mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold text-gray-900">Shopping Cart</h1>
          <p className="text-gray-600 mt-2">
            {cartItems.length} items in your cart
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Cart Items */}
          <div className="lg:col-span-2 space-y-6">
            <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
              {cartItems.map((item) => (
                <div
                  key={item.id}
                  className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 pb-6 border-b border-gray-100 last:border-0 last:pb-0"
                >
                  <div className="flex flex-col sm:flex-row justify-between w-full gap-24">
                    <div className="space-y-1">
                      <h3 className="text-left font-medium text-gray-900 text-lg">
                        {item.title}
                      </h3>
                      {item.type === "event" && item.date && item.time && (
                        <p className="text-left text-gray-500">
                          {item.date}, {item.time}
                        </p>
                      )}
                      {item.type === "event" && item.location && (
                        <p className="text-left text-gray-500">
                          {item.location}
                        </p>
                      )}
                      {item.type === "resource" && item.exam && (
                        <p className="text-left text-gray-500">
                          Exam board: {item.exam}
                        </p>
                      )}
                      <div style={{ display: "flex" }}>
                        {" "}
                        <span className=" te inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          {item.type === "resource" ? "Resource" : "Event"}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-6">
                      {item.type === "resource" && (
                        <div className="flex items-center gap-3 bg-gray-50 rounded-lg px-3 py-1">
                          <button
                            onClick={() =>
                              updateQuantity(item.id, item.quantity - 1)
                            }
                            className="text-gray-500 hover:text-gray-700 transition"
                          >
                            <Minus className="w-4 h-4" />
                          </button>
                          <span className="w-8 text-center font-medium">
                            {item.quantity}
                          </span>
                          <button
                            onClick={() =>
                              updateQuantity(item.id, item.quantity + 1)
                            }
                            className="text-gray-500 hover:text-gray-700 transition"
                          >
                            <Plus className="w-4 h-4" />
                          </button>
                        </div>
                      )}
                      <p className="font-medium text-lg text-gray-900">
                        {item.price}
                      </p>
                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="text-gray-400 hover:text-red-500 transition"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Add More Section */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Add more items
              </h3>
              <div className="flex gap-4">
                <button
                  onClick={() => navigate("/events")}
                  className="flex-1 py-3 px-4 bg-yellow-50 text-yellow-700 rounded-lg hover:bg-yellow-100 transition text-sm font-medium"
                >
                  Browse our Events
                </button>
                <button
                  onClick={() => navigate("/resources")}
                  className="flex-1 py-3 px-4 bg-yellow-50 text-yellow-700 rounded-lg hover:bg-yellow-100 transition text-sm font-medium"
                >
                  Browse our Resources
                </button>
              </div>
            </div>
          </div>

          {/* Summary */}
          <div className="lg:row-start-1 lg:col-start-3">
            <div className="bg-white rounded-xl shadow-sm p-6 sticky top-4">
              <h2 className="text-xl font-bold text-gray-900 mb-6">
                Order Summary
              </h2>
              <div className="space-y-4">
                <div className="flex justify-between border-t pt-4">
                  <span className="text-lg font-medium">Total</span>
                  <span className="text-2xl font-bold text-yellow-600">
                    {hasOnlyFreeItems
                      ? "Free"
                      : `£${calculateTotal().toFixed(2)}`}
                  </span>
                </div>
                <button
                  onClick={handleCheckout}
                  className="w-full bg-yellow-600 text-white py-4 rounded-lg hover:bg-yellow-700 transition flex items-center justify-center gap-2 font-medium"
                >
                  {hasOnlyFreeItems
                    ? "Complete Registration"
                    : "Proceed to Checkout"}
                  <ArrowRight className="w-5 h-5" />
                </button>
                {!hasOnlyFreeItems && (
                  <p className="text-sm text-gray-500 text-center mt-4">
                    Secure checkout powered by Stripe
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
