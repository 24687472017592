import React, { useEffect } from "react";
import "./style.css";
import { useForm } from "react-hook-form";
import Box from "../Dashboard/Box";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner
  }, []);
  const onSubmit = (data) => {
    // Handle form submission logic here
    console.log(data);
    reset(); // Optionally reset the form after submission
  };

  return (
    <div>
      <section class="c-pageBanner c-pageBanner--courses sectionContainer ">
        <div class="c-pageBanner__backgroundOverlay"></div>
        <div style={{ paddingTop: "0px" }} class="c-pageBanner__container  ">
          <div className="c-contact">
            <div className="c-contact__content">
              <h1 className="c-contact__contentTitle">Contact form</h1>
              <p
                style={{ fontWeight: "bold", fontSize: "22px" }}
                className="c-contact__contentIntro"
              >
                If you would like to learn more about
              </p>
              <ul className="c-contact__list">
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                  }}
                >
                  CPD sessions
                </li>
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                  }}
                >
                  student events
                </li>
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                  }}
                >
                  ordering resources
                </li>
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                  }}
                >
                  student subscriptions
                </li>
                <li
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    padding: 0,
                    marginTop: 22,
                  }}
                >
                  working with us (careers)
                </li>
              </ul>
              <p
                style={{ fontWeight: "bold", fontSize: "22px", marginTop: 22 }}
                className="c-contact__followUp"
              >
                get in touch and we will connect with you soon.
              </p>
              {/* <div className="c-contact__infos">
              <div className="c-contact__info">
                <i className="fa-solid fa-address-book c-contact__infoIcon"></i>
                <div className="c-contact__infoContent">
                  <div className="c-contact__infoContentTitle">
                    Contact us by email
                  </div>
                  <div className="c-contact__infoContentText">
                    learning@dilepex.com
                  </div>
                </div>
              </div>
              <div className="c-contact__info">
                <i className="fa-solid fa-phone c-contact__infoIcon"></i>
                <div className="c-contact__infoContent">
                  <div className="c-contact__infoContentTitle">
                    Contact us by phone
                  </div>
                  <div className="c-contact__infoContentText">
                    +44 7487220123
                  </div>
                </div>
              </div>
            </div> */}
            </div>
            <div className="c-contact__form">
              <form
                className="form-container"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-div">
                  <div className="form-text-div">
                    <div className="Labelcontent">
                      School or Institution Name
                      <span
                        style={{
                          fontWeight: "300",
                          color: "green",
                          fontSize: 18,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      placeholder="School or Institution Name"
                      className="inputContent"
                      {...register("school_name", { required: true })}
                    />
                  </div>
                  <div className="form-text-div">
                    <div className="Labelcontent">
                      Name
                      <span
                        style={{
                          fontWeight: "300",
                          color: "green",
                          fontSize: 18,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      placeholder="Name"
                      className="inputContent"
                      {...register("name", { required: true })}
                    />
                  </div>
                </div>
                <div className="form-div">
                  <div className="form-text-div">
                    <div className="Labelcontent">
                      Email Address
                      <span
                        style={{
                          fontWeight: "300",
                          color: "green",
                          fontSize: 18,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      placeholder="Email Address"
                      className="inputContent"
                      {...register("email_address", { required: true })}
                    />
                    {errors.email_address && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="form-text-div">
                    <div className="Labelcontent">
                      Position
                      <span
                        style={{
                          fontWeight: "300",
                          color: "green",
                          fontSize: 18,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <select
                      className="inputContent"
                      {...register("position", { required: true })}
                    >
                      <option value="">Select a position</option>
                      <option value="Student">Student</option>
                      <option value="Teacher">Teacher</option>
                      <option value="Examiner">Examiner</option>
                    </select>
                    {errors.position && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-text-div">
                  <div className="Labelcontent">Your message</div>
                  <input
                    placeholder="Please enter your message"
                    className="inputContent"
                    style={{ width: "100%", height: 120 }}
                    {...register("description")}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <button type="submit" className="submitButton">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="c-contact__infoMessage"></div> */}
          </div>
        </div>
      </section>
      <div className="bgContainer linearBG">
        <Box />
      </div>
    </div>
  );
};

export default ContactForm;
